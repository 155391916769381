.destinySymbol {
    font-family: DestinySymbols;
}

.info {
    padding-top: 70px;
    padding-left: 10px;
    padding-bottom: 20px;
}

.display-in-row {
    display: flex;
    flex-direction: row;
}

.display-flex {
    display: flex;
}

.display-in-row-wrap {
    display: flex;
    flex-flow: row wrap;
}

.display-in-row-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row-space {
    justify-content: space-evenly;
}

.row-margin > * {
    margin-right: 5px;
    margin-bottom: 5px;
}

.icon-m5 {
    margin-right: 5px;
    margin-bottom: 5px;
}

.bg-teal {
    background-color: #297373;
}

.overflowAuto {
    overflow: auto;
    max-height: 100%;
}

.overflowAuto80 {
    overflow: auto;
    max-height: 80%;
}

.min-width {
    width: min-content;
}

.itemCard {
    padding: 7px;
    font-size: 20px;
    width: max-content;
    border-radius: 5px;
}

.center-vertical {
    align-items: center;
}

.center {
    text-align: center;
}

.p5 {
    padding: 5px;
}

.pr5 {
    padding-right: 5px;
}

.pl5 { 
    padding-left: 5px;
}

.mb5 {
    margin-bottom: 5px;
}

.ml5 {
    margin-left: 5px;
}

.mr5 {
    margin-right: 5px;
}

.mt10 {
    margin-top: 10px;
}

.rewardItem {
    background-size: cover;
    display: block;
    background-repeat: no-repeat;
    height: 100%;
}

.rewardWatermark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background-size: cover;
    display: block;
    background-repeat: no-repeat;
    width: 100%;
}

.rewardContainer {
    height: 100px;
    width: 100px;
    position: relative;
}

.rewardIcon {
    height: 30px;
    margin-right: 5px;
}

.md-rewardIcon {
    height: 60px;
    margin-right: 5px;
}

.armorIcon {
    margin-right: 0px;
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(333deg) brightness(109%) contrast(101%);
}

.highlight {
    background-color: #297373;
}

.highlight2 {
    background-color: #ff6c2d;
    color: black;
}

.rotationTable1 {
    flex-grow: 1;
    padding-right: 10px;
}

.rotationTable2 {
    flex-grow: 2;
}

.rounded-corners {
    border-radius: 5px;
}

.width60 {
    width: 60%;
}

.width50 {
    width: 50%;
}

.weaponIcon {
    width: 50px;
    padding: 5px;
}

.seasonIcon {
    width: 75px;
}

.subTitle {
    font-size: 20px;
}

.App {
    position: relative;
    min-height: 100vh;
}

.content-wrap {
    padding-bottom: 2rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2rem;
    color: lightgray;
    align-items: center;
    justify-content: center;
}

.footer > * {
    padding-right: 5px;
}

.footerIcon {
    font-size: 20px;
    color: lightgray;
}

.calendar > * {
    margin-right: 10px;
    margin-bottom: 10px;
}

.borderBox {
    border: 1px white solid;
    border-radius: 10px;
    padding: 3px;
    margin-right: 2px;
}

.dark-background {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}

.exoticMission > div {
    height: 80%;
}
.smallCard {
    width: 250px;
}

.calendarCard {
    width: 400px;
}

.cardTitle {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    text-align: center;
    padding: 2px;
}

.calendarTitle {
    text-align: center;
    padding-top: 5px;
}

.largeCardTitle {
    font-size: 25px;
    margin-bottom: 10px;
}

.largeCard {
    height: 100% !important;
}

.rotationBox {
    background-color: rgb(33, 30, 30);
    border-radius: 0 0 3px 3px;
}

.rounded-bottom {
    border-radius: 0 0 3px 3px;
}

.card.with-footer {
    border-radius: 3px 3px 0 0;
}

.raidDungeonCard{ 
    max-width: 700px;
    margin-bottom: 5px;
}
.nav-item > .nav-link {
    color: white !important;
    background-color: teal !important;
}

.nav-item > .nav-link:hover {
    background-color: grey !important;
}

.nav-item > .nav-link.active {
    background-color: #ff8552 !important;
    color: black !important;
}

.tab-pane {
    background-color: #85ffc7 !important;
}

.tab-pane > div {
    background-color: #297373;
}

.description {
    padding: 5px;
}

.tabsTable {
    opacity: 90%;
}
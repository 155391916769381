.card-body { 
    background-color: #297373;
    border-radius: 3px;
}

.lostSectorCard {
    opacity: 90%;
    background-color: #297373 !important;
    margin-bottom: 5px;
}

.smallIcon {
    width: 15px;
}

.rewardRotation {
    margin-top: 10px;
    border: 1px solid white;
    border-radius: 3px;
    justify-content: space-around;
}

.reward {
    width: 100%;
    text-align: center;
}

.lostSector {
    width: 50%;
}